<template>
	<div class="profile">
		<h1 class="">Профиль</h1>
		<p class="mt-4">Это тестовая учетная запись.</p>
		<p>Для изменения email, пожалуйста, обратитесь в Поддержку.</p>

		<v-form @submit.prevent=""
				@keyup.native.enter=""
				v-model="isFormValid" class="mt-12">
			<v-row>
				<v-col cols="12" sm="6" md="4">
					<v-text-field v-model="form.EMAIL" label="E-mail"
								  required filled readonly></v-text-field>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12" sm="6" md="4">
					<v-text-field v-model="form.NAME" label="Имя"
								  required filled></v-text-field>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12" sm="6" md="4">
					<v-text-field v-model="form.PHONE_NUMBER" label="Телефон"
								  required filled></v-text-field>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12" sm="6" md="4">
					<v-btn color="primary" block @click="save">Сохранить</v-btn>
				</v-col>
			</v-row>
		</v-form>

		<v-snackbar
			v-model="isSnackSaved"
			color="green"
		>
			Профиль обновлен!

			<template v-slot:action="{ attrs }">
				<v-btn
					text
					v-bind="attrs"
					@click="isSnackSaved = false"
				>
					Ок
				</v-btn>
			</template>
		</v-snackbar>
	</div>
</template>

<script>
export default {
	name: "profile",
	data: () => ({
		loading: false,
		isFormValid: true,
		isSnackSaved: false,
		error: null,
		form: {
			EMAIL: null,
			NAME: null,
			PHONE_NUMBER: null,
		}
	}),
	methods: {
		save(withSubmit = false) {
			this.loading = true;
			this.error = null;

			const params = this.form || {};
			return this.$store.dispatch('post', {
				action: 'ProfileController',
				params
			}).then((res) => {
				//this.$router.push({name: "orders"});

				this.isSnackSaved = true;

			}).catch((error) => {
				this.error = error ? error : 'Не получилось...';
			}).finally(() => {
				this.loading = false;
			});
		},
	},
	mounted() {
		this.form.EMAIL = this.$store.state.session.user.EMAIL;
		this.form.NAME = this.$store.state.session.user.NAME;
		this.form.PHONE_NUMBER = this.$store.state.session.user.PHONE_NUMBER;
	}
}
</script>

<style lang="scss">

</style>